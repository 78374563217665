export const menuItems = [
         {
           id: 0,
           label: "AGENT MENU",
           isTitle: true,
         },
         {
           id: 1,
           label: "menuitems.agent.list.dashboard",
           icon: "ri-dashboard-line",
           link: "/",
         },
         {
           id: 2,
           label: "menuitems.agent.list.customers",
           icon: "ri-user-2-line",
           link: "/agent/customers",
         },
         {
           id: 3,
           label: "menuitems.agent.list.transactions",
           icon: "ri-list-check",
           link: "/agent/transactions",
         },
         {
           id: 4,
           label: "menuitems.agent.list.groups",
           icon: "ri-group-line",
           link: "/agent/groups",
         },
       ];


